<template>
    <div class="advise">
        <van-nav-bar left-arrow title="吐槽和建议" @click-left="onClickLeft" />
        <van-dropdown-menu ref="dropdown">
            <!-- <van-dropdown-item title="状态" v-model="status" :options="statusList" /> -->
            <van-dropdown-item title="筛选" ref="itemRef">
                <van-cell center title="日期"> <van-Field placeholder="请输入日期" @click="selDate" v-model="date"
                        readonly /></van-cell>
                <van-calendar v-model:show="isShowDate" @confirm="onSelConfirm" />
                <van-cell center title="反馈编码"><van-Field placeholder="请输入反馈编码" v-model="code" /></van-cell>
                <van-cell center title="关键字"><van-Field placeholder="请输入关键字" v-model="word" /></van-cell>
                <van-cell center title="状态"> <van-Field placeholder="请输入状态" @click="selType" v-model="status"
                        readonly /></van-cell>
                <van-popup v-model:show="showTypePicker" position="bottom">
                    <van-picker :columns="typeList" @confirm="onTypeConfirm" @cancel="showTypePicker = false" />
                </van-popup>
                <div class="btn-box">
                    <van-button class="search-button button" size="small" type="primary" @click="onSearch">查询</van-button>
                    <van-button class="reset-button button" size="small" type="danger" @click="onReset">重置</van-button>
                </div>
            </van-dropdown-item>
        </van-dropdown-menu>
        <div class="list">
            <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                <div class="item" @click="onShowDetails(item)" v-for="(item, index) in list" :key="index">
                    <div class="tag done" v-if="item.deal_status === 2">已解决</div>
                    <div class="tag doing" v-if="item.deal_status === 1">处理中</div>
                    <div class="tag" v-if="item.deal_status === 0">未处理</div>
                    <div class="code text"><span>点检时段：</span>{{ item.code }}</div>
                    <div class="date text"><span>完成时间：</span>{{ item.create_time }}</div>
                    <div class="dec text van-multi-ellipsis--l2"><span>内容详情：</span>{{ item.content }}</div>
                </div>
            </van-list>
        </div>
        <!-- details -->
        <van-action-sheet v-model:show="isShowDetails" title="详情" :style="{ padding: '2vw', height: '150vw' }"
            position="bottom">
            <div class="model-box">
                <div class="question-top van-hairline--bottom">
                    <div><van-tag size="medium" type="success">问题详情：</van-tag></div>
                    <div class="content">
                        {{ question }}
                    </div>
                </div>
                <div class="question-bottom">
                    <div><van-tag size="medium" type="primary">反馈详情：</van-tag></div>
                    <div class="content">
                        {{ answer }}
                    </div>
                </div>
            </div>

        </van-action-sheet>
    </div>
</template>
<script>
import { ref, onMounted } from 'vue';
import moment from 'moment';
import { getOpinions } from "@/request/api.js";
import { useRouter } from "vue-router";
export default {
    name: "adviseM",
    setup() {
        const date = ref('');
        const status = ref('');
        const word = ref('');
        const code = ref('');
        const question = ref('');
        const answer = ref('');
        const loading = ref(false);
        const showTypePicker = ref(false);
        const finished = ref(true);
        const isShowDate = ref(false);
        const isShowDetails = ref(false);
        const router = useRouter();
        // const pageSize = ref(100);
        // const pageIndex = ref(1);
        let list = ref([]);
        const typeList = [
            { text: '未处理', value: '未处理' },
            { text: '已解决', value: '已解决' },
            { text: '处理中', value: '处理中' }
        ];
        const mapTypeObj = {
            '未处理': '0',
            '已解决': '2',
            '处理中': '1',
        };
        const statusList = [
            { text: '已解决', value: 0 },
            { text: '处理中', value: 1 },
            { text: '未处理', value: 2 },
        ];
        const onClickLeft = () => history.back();
        const opinions = async () => {
            const curData = {};
            if (date.value) {
                curData.created_at = date.value;
            }
            if (word.value) {
                curData.keyword = word.value;
            }
            if (code.value) {
                curData.code = code.value;
            }
            if (status.value) {
                curData.deal_status = mapTypeObj[status.value]
            }

            const { data } = await getOpinions(curData);
            list.value = data.data.list;
        };
        function isMobileDevice() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        }
        onMounted(async () => {
            if (!isMobileDevice()) {
                router.replace("/advisepc");
            }
            opinions();
            console.log('Component mounted');
        });
        return {
            word,
            statusList,
            onClickLeft,
            code,
            isShowDate,
            date,
            status,
            loading,
            finished,
            isShowDetails,
            opinions,
            list,
            typeList,
            showTypePicker,
            question,
            answer
        };
    },
    methods: {
        onTypeConfirm(v) {
            console.log('v---', v);
            this.status = v.selectedValues[0];
            this.showTypePicker = false
        },
        selType() {
            this.showTypePicker = true
        },
        selDate() {
            // alert('test')
            this.isShowDate = true
        },
        onSelConfirm(v) {
            console.log('v---', v);
            this.date = moment(v).format('YYYY-MM-DD');
            this.isShowDate = false;
        },
        onLoad() {

        },
        onShowDetails(item) {
            this.isShowDetails = true;
            this.answer = item.reply_content;
            this.question = item.content;

        },
        onSearch() {
            this.opinions();
            this.$refs.dropdown.close();
        },
        onReset() {
            this.date = '';
            this.word = '';
            this.code = '';
            this.status = '';
            this.opinions();
            this.$refs.dropdown.close();
        }
    }
}
</script>
<style lang="scss" scoped>
.advise {
    min-height: 100vh;
    background: #F4F6F8;
    // background: white;
    padding-bottom: 15vw;

    .imgbox {
        background: white;
        margin: 0 4vw;
        position: relative;

        img {
            width: 100%;
            height: auto;
        }

        .more {
            text-align: center;
            font-size: 3vw;
            padding: 3vw 0;
            color: rgba(128, 128, 128, 1);
        }

        video {
            width: 100%;
            height: 40vw;
        }

        .e-button {
            position: absolute;
            color: white;
            font-size: 3.1vw;
            background: rgba(191, 8, 8, 1);
            bottom: 1vw;
            right: 1vw;
            padding: 1vw 2vw;
            border-radius: 1vw;
        }
    }

    .sec6 {
        margin-top: 4vw;
    }

    .sec7 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        img {
            width: 41.5vw;
            margin: 3vw 0vw 0 0;
        }
    }

    img {
        width: 100%;

    }

    .btn-box {
        display: flex;
        justify-content: space-around;
    }

    .button {
        margin: 5vw 0;
        width: 30vw;
    }

    .list {
        margin: 4vw;
        background: #F4F6F8;
    }

    .item {
        position: relative;
        background: white;
        padding: 3vw;
        margin-bottom: 3vw;
        border-radius: 2vw;

        .tag {
            position: absolute;
            right: 4vw;
            top: 3.5vw;
            width: 12vw;
            height: 5vw;
            line-height: 5vw;
            border-radius: 1.3vw;
            text-align: center;
            font-size: 3vw;
            color: white;
            // box-shadow: 0px 0.4vw 0.6vw 0.2vw rgba(206,212,225,0.45);
            background: #FF7D48;
        }

        .text {
            color: #999999;
            font-size: 3.5vw;
            line-height: 5.5vw;

            span {
                color: #333;
                display: inline-block;
                width: 18vw;
                text-align: right;
            }
        }

        .done {
            background: #006DE3;
        }

        .doing {
            background: #006DE3;
        }

    }

    .question-top {
        padding-bottom: 5vw;
        // .content {
        //     height: 75vw;
        // }
    }

    .question-bottom {
        margin-top: 5vw;
    }

    .content {
        padding-top: 2vw;
    }

    .model-box {
        position: relative;

        .cross {
            position: absolute;
            top: 3vw;
            right: 3vw;
        }
    }
}
</style>