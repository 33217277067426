import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import {
    Button,
    Tabbar,
    TabbarItem,
    Icon,
    DropdownMenu,
    DropdownItem,
    NavBar,
    Cell,
    Field,
    DatePicker,
    Calendar,
    List,
    Popup,
    Tag,
    ActionSheet,
    Form,
    CellGroup,
    Picker,
    Uploader,
    Toast,
    ShowToast,
    ImagePreview,
    Dialog,
    SwipeItem,
    Swipe
} from "vant";
import '@/assets/css/_atomic.scss';
import "vant/lib/index.css";
import App from "./App.vue";
import router from "./router";
const app = createApp(App);
app.use(Button)
    .use(List)
    .use(Tabbar)
    .use(TabbarItem)
    .use(Icon)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(Calendar)
    .use(NavBar)
    .use(Cell)
    .use(Field)
    .use(DatePicker)
    .use(Picker)
    .use(Popup)
    .use(ActionSheet)
    .use(Form)
    .use(Uploader)
    .use(CellGroup)
    .use(Toast)
    .use(ShowToast)
    .use(ImagePreview)
    .use(Dialog)
    .use(Tag)
    .use(SwipeItem )
    .use(Swipe);
app.use(router);
app.use(ElementPlus);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.mount("#app");
