<template>
    <div class="pc-container">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :ellipsis="false"
            @select="handleSelect">
            <img class="logo" src="../../public/imgs/logo.png" />
            <div class="flex-grow" />
            <el-menu-item index="1" @click="onNavQuestion('/homepc')">首页</el-menu-item>
            <el-button class="process" type="plain" @click="onNavQuestion('/homepc?type=progress')">我们的进步</el-button>
            <el-menu-item index="2" @click="onNavQuestion('/questionpc')">问题反馈</el-menu-item>
        </el-menu>
        <div class="question">
            <el-tabs v-model="activeName" class="demo-tabs">
                <el-tab-pane label="问题反馈" name="first">
                    <el-form :model="form" label-width="120px" ref="ruleFormRef" :rules="rules">
                        <el-form-item label="问题类别" prop="type">
                            <el-select v-model="form.type" placeholder="请选择问题类别" style="width: 100%">
                                <el-option v-for="(item, index) in typeList" :key="index" :label="item.title"
                                    :value="item.id" />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="问题和反馈" prop="content">
                            <el-input v-model="form.content" type="textarea" placeholder="请输入您遇到的问题" />
                        </el-form-item>
                        <el-form-item label="" prop="imgs">
                            <el-upload v-model:file-list="form.imgs" class="upload-demo"
                                action="https://tsht.fjxzjy.com/api/user/uploadImg" multiple :on-preview="handlePreview"
                                :on-remove="handleRemove" :before-remove="beforeRemove" :limit="3" :on-exceed="handleExceed"
                                accept="image/*" name="image">
                                <el-button type="primary">点击上传图片</el-button>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="联系人" prop="name">
                            <el-input v-model="form.name" placeholder="请输入联系人姓名" />
                        </el-form-item>
                        <el-form-item label="手机号" prop="phone">
                            <el-input v-model="form.phone" placeholder="请输入联系方式" />
                        </el-form-item>
                        <el-form-item label="是否保密" prop="is_safe">
                            <el-select v-model="form.is_safe" placeholder="是否隐藏问题详情" style="width: 100%">
                                <el-option v-for="(item, index) in secrecyList" :key="index" :label="item.text"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <div style="display: flex; justify-content: center; width: 100%">
                                <el-button type="primary" @click="onSubmit(ruleFormRef)"> 提交反馈 </el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
        </div>
        <el-dialog v-model="isShowCode" width="30%" align-center>
            <div style="display: flex; align-items: center; justify-content: center">
                <div>
                    <div class="dialog-item">
                        <el-icon>
                            <SuccessFilled color="#00a870" />
                        </el-icon>反馈成功
                    </div>
                    <div class="dialog-item">反馈编码：{{ dialogCode }}</div>
                    <div class="dialog-item">所有反馈将在3个工作日内回复！</div>
                    <div class="dialog-item">（保密反馈仅能通过编码查询，请保存好自己的编码）</div>
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" @click="
                        isShowCode = false;
                    router.push('/homepc');
                    ">
                        确定
                    </el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { addOpinion, getAnswerTypes } from "@/request/api.js";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
export default {
    name: "questionPc",
    setup() {
        const router = useRouter();
        const showTypePicker = ref(false);
        const activeName = ref("first");
        const activeIndex = ref("2");
        const isShowCode = ref(false);
        const dialogCode = ref("");
        const form = ref({
            type: "",
            phone: "",
            name: "",
            content: "",
            imgs: [],
            is_safe: "",
        });
        const rules = {
            type: [{ required: true, message: "请选择问题类别", trigger: "blur" }],
            name: [{ required: true, message: "请输入联系人姓名", trigger: "blur" }],
            phone: [{ required: true, message: "请输入联系方式", trigger: "blur" }],
            content: [{ required: true, message: "请输入您遇到的问题", trigger: "blur" }],
            imgs: [{ required: true, message: "请选择图片上传", trigger: "blur" }],
            is_safe: [{ required: true, message: "请选择是否隐藏问题详情", trigger: "blur" }],
        };
        const ruleFormRef = ref();
        const typeList = ref([]);
        const secrecyList = [
            { text: "是", value: "1" },
            { text: "否", value: "0" },
        ];
        const imgs = ref([]);
        const content = ref("");
        const name = ref("");
        const phone = ref("");
        const onClickLeft = () => history.back();
        const type = ref("");
        const is_safe = ref("");
        const showSecrecyPicker = ref(false);
        const getAnswerTypesFun = async ()=>{
          const {data} = await getAnswerTypes();
          if(data.code===0){
            typeList.value = data.data;
            console.log('typeList.value',typeList.value)
          }
        }
        const onSubmit = async (formEl) => {
            console.log("form img.value", form.value);
            if (!formEl) return;
            formEl.validate(async (valid) => {
                if (valid) {
                    console.log("submit!", form.value);
                    const curFormData = JSON.parse(JSON.stringify(form.value));
                    let curImg = "";
                    form.value.imgs.forEach((item, index) => {
                        if (index === 0) {
                            curImg = item.response.data.image;
                        } else {
                            curImg = curImg + "," + item.response.data.image;
                        }
                    });
                    curFormData.img = curImg;
                    const { data } = await addOpinion(curFormData);
                    if (data.code === 0) {
                        dialogCode.value = data.data.code;
                        isShowCode.value = true;
                    } else {
                        ElMessage({
                            message: data.msg,
                            type: "warning",
                        });
                    }
                } else {
                    console.log("error submit!");
                    return false;
                }
            });
        };
        function isMobileDevice() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        }
        onMounted(async () => {
            if (isMobileDevice()) {
                router.replace("/questionM");
            }
            getAnswerTypesFun()
        });
        return {
            showTypePicker,
            typeList,
            type,
            content,
            imgs,
            name,
            phone,
            is_safe,
            showSecrecyPicker,
            secrecyList,
            onSubmit,
            onClickLeft,
            activeName,
            form,
            ruleFormRef,
            rules,
            activeIndex,
            isShowCode,
            dialogCode,
            router,
        };
    },
    methods: {
        onTypeConfirm(v) {
            console.log("getBlackList", addOpinion);
            // addOpinion();
            console.log(v);
            this.type = v.selectedValues[0];
            this.showTypePicker = false;
        },
        onSecrecyConfirm(v) {
            this.is_safe = v.selectedValues[0];
            this.showSecrecyPicker = false;
        },
        handleRemove() { },
        beforeRemove() { },
        onNavQuestion(url) {
            this.$router.push(url);
        },
    },
};
</script>
<style lang="scss" scoped>
.pc-container {
    background: #f4f5f7;

    .logo {
        height: 50px;
    }

    .process {
        border: 0;
        height: 56px;
        color: #303133;

        a {
            color: #303133;
        }
    }
}

.question {
    margin: 0 200px;
    padding: 0 20px;
    background: white;

    .bar {
        margin-bottom: 3vw;
    }

    min-height: 100vh;
    // background: white;
    padding-bottom: 15vw;

    .imgbox {
        background: white;
        margin: 0 4vw;
        position: relative;

        img {
            width: 100%;
            height: auto;
        }

        .more {
            text-align: center;
            font-size: 3vw;
            padding: 3vw 0;
            color: rgba(128, 128, 128, 1);
        }

        video {
            width: 100%;
            height: 40vw;
        }

        .e-button {
            position: absolute;
            color: white;
            font-size: 3.1vw;
            background: rgba(191, 8, 8, 1);
            bottom: 1vw;
            right: 1vw;
            padding: 1vw 2vw;
            border-radius: 1vw;
        }
    }

    .sec6 {
        margin-top: 4vw;
    }

    .sec7 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        img {
            width: 41.5vw;
            margin: 3vw 0vw 0 0;
        }
    }

    img {
        width: 100%;
    }

    .btn-box {
        display: flex;
        justify-content: space-around;
    }

    .button {
        margin: 5vw 0;
        width: 30vw;
    }

    .list {
        margin: 4vw;
        background: #f4f6f8;
    }

    .item {
        position: relative;
        background: white;
        padding: 3vw;
        margin-bottom: 3vw;
        border-radius: 2vw;

        .tag {
            position: absolute;
            right: 4vw;
            top: 3.5vw;
            width: 12vw;
            height: 5vw;
            line-height: 5vw;
            border-radius: 1.3vw;
            text-align: center;
            font-size: 3vw;
            color: white;
            // box-shadow: 0px 0.4vw 0.6vw 0.2vw rgba(206,212,225,0.45);
            background: #ff7d48;
        }

        .text {
            color: #999999;
            font-size: 3.5vw;
            line-height: 5.5vw;

            span {
                color: #333;
                display: inline-block;
                width: 18vw;
                text-align: right;
            }
        }

        .done {
            background: #006de3;
        }

        .doing {
            background: #006de3;
        }
    }

    .question-top {
        padding-bottom: 5vw;
        // .content {
        //     height: 75vw;
        // }
    }

    .question-bottom {
        margin-top: 5vw;
    }

    .model-box {
        position: relative;

        .cross {
            position: absolute;
            top: 3vw;
            right: 3vw;
        }
    }
}

.f-c {
    display: flex;
    justify-items: center;
    justify-content: center;
}

.dialog-item {
    line-height: 30px;
    font-size: 16px;
}
</style>
