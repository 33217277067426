<template>
    <div class="pc-container">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :ellipsis="false" @select="handleSelect">
            <img class="logo" src="../../public/imgs/logo.png" />
            <div class="flex-grow" />
            <el-menu-item index="1" @click="onNavQuestion('/homepc')">首页</el-menu-item>
            <el-button class="process" type="plain" @click="onNavQuestion('/homepc?type=progress')">我们的进步</el-button>
            <el-menu-item index="2" @click="onNavQuestion('/questionpc')">问题反馈</el-menu-item>
        </el-menu>
        <div class="bg-w content">
            <div class="tab">
                <div class="title">吐槽与建议</div>
                <div class="line"></div>
            </div>
            <div>
                <el-form :inline="true" :model="formInline" class="demo-form-inline">
                    <el-form-item label="日期">
                        <el-date-picker v-model="formInline.created_at" type="date" placeholder="请选择日期" clearable />
                    </el-form-item>
                    <el-form-item label="反馈编码">
                        <el-input v-model="formInline.code" placeholder="请输入反馈编码" clearable />
                    </el-form-item>
                    <el-form-item label="关键字">
                        <el-input v-model="formInline.keyword" placeholder="请输入关键字" clearable />
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-select v-model="formInline.deal_status" placeholder="请选择状态" clearable>
                            <el-option v-for="(item, index) in statusList" :key="index" :label="item.text" :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSearch">查询</el-button>
                        <el-button type="danger" @click="onReset">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div>
                <el-table :data="tableData" stripe style="width: 100%" @rowClick="onRowClick">
                    <el-table-column prop="create_time" label="日期" width="180" />
                    <el-table-column prop="code" label="反馈编码" width="180" />
                    <el-table-column prop="content" label="详情" />
                    <el-table-column prop="deal_status_name" label="状态" />
                </el-table>
                <div style="display: flex; justify-content: flex-end">
                    <el-pagination
                        style="padding: 20px"
                        v-model:current-page="pageIndex"
                        v-model:page-size="pagesize"
                        background
                        layout="prev, pager, next"
                        :total="pageNum"
                        @preClick="onPreClick"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    />
                </div>
            </div>
        </div>
        <el-dialog v-model="dialogVisible" title="详情" width="60%" :before-close="handleClose">
            <div>
                <div>
                    <div>
                        <div class="qusBtn">问题详情：</div>
                    </div>
                    <div class="d-dec">
                        {{ curRow.content }}
                    </div>
                    <div class="d-date">{{ curRow.create_time }} 反馈编码:{{ curRow.code }}</div>
                </div>
                <el-divider />
                <div>
                    <div>
                        <div class="feedbackBtn">反馈详情：</div>
                    </div>
                    <div class="d-dec">
                        {{ curRow.reply_content }}
                    </div>
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" @click="dialogVisible = false"> 确定 </el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { getOpinions } from "@/request/api.js";
import { useRouter } from "vue-router";
export default {
    name: "blacklistPc",
    setup() {
        const actId = ref("");
        const platform = ref("");
        const word = ref("");
        const nickame = ref("");
        const content = ref("");
        const loading = ref(false);
        const finished = ref(true);
        const isShowDetails = ref(false);
        const formInline = ref({});
        const tableData = ref([]);
        const pagesize = ref(10);
        const pageIndex = ref(1);
        const pageNum = ref(0);
        const dialogVisible = ref(false);
        const curRow = ref({});
        let list = ref([]);
        const router = useRouter();
        const statusList = [
            { text: "未处理", value: 0 },
            { text: "处理中", value: 1 },
            { text: "已解决", value: 2 },
        ];
        const onRowClick = (row) => {
            console.log("row", row);
            dialogVisible.value = true;
            curRow.value = row;
        };
        const onClickLeft = () => history.back();
        const opinions = async () => {
            const { data } = await getOpinions({ ...formInline.value, page_size: pagesize.value, page_index: pageIndex.value });
            tableData.value = data.data.list;
            pageNum.value = data.data.page_info.total_num;
        };
        const onPreClick = () => {
            console.log("onPreClick");
        };
        const handleSizeChange = (val) => {
            console.log(`${val} items per page`);
        };
        const handleCurrentChange = (val) => {
            console.log(`current page: ${val}`);
            opinions();
        };
        function isMobileDevice() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        }
        onMounted(async () => {
            if (isMobileDevice()) {
                router.replace("/adviseM");
            }
            opinions();
            console.log("Component mounted");
        });
        return {
            nickame,
            word,
            platform,
            actId,
            statusList,
            onClickLeft,
            loading,
            finished,
            isShowDetails,
            opinions,
            list,
            content,
            formInline,
            tableData,
            pageNum,
            pageIndex,
            pagesize,
            onPreClick,
            handleSizeChange,
            handleCurrentChange,
            dialogVisible,
            onRowClick,
            curRow,
        };
    },
    methods: {
        async onSearch() {
            this.pageNum = 1;
            this.opinions();
        },
        onShowDetails(item) {
            this.isShowDetails = true;
            this.content = item.content;
        },
        getMaliciousAccounts() {
            this.isShowDetails = true;
        },
        onReset() {
            this.pageIndex = 1;
            this.formInline = {};
            this.opinions();
        },
        onNavQuestion(url) {
            this.$router.push(url);
        },
    },
};
</script>
<style lang="scss" scoped>
.pc-container {
    background: #f4f5f7;

    .logo {
        height: 50px;
    }
}

.top-nav {
    background: white;
}

.process {
    border: 0;
    height: 56px;
    color: #303133;

    a {
        color: #303133;
    }
}

.bg-w {
    background: white;
}

.content {
    padding: 0 13%;
}

.tab {
    padding: 30px 0;

    .title {
        font-size: 24px;
        color: #333333;
        line-height: 50px;
    }

    .line {
        height: 3px;
        width: 122px;
        border: 1px;
        background: #333;
    }
}
.qusBtn {
    width: 80px;
    height: 30px;
    line-height:30px;
    text-align: center;
    border-radius: 2px;
    background: rgba(255, 232, 209, 1);
    color: rgba(255, 128, 0, 1);
}
.feedbackBtn {
    width: 80px;
    height: 30px;
    line-height:30px;
    text-align: center;
    border-radius: 2px;
    background: rgba(236, 242, 254, 1);
    color: rgba(0, 82, 217, 1);
}
.d-dec{
    padding:20px;
}
.d-date{
    text-align: right;
    color:rgba(166, 166, 166, 1);
}
</style>
