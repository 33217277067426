import request from "./axios";
import requestqr from "./axios-qr";


export function addOpinion(params) {
    return request({
        url: "/user/addOpinion",
        method: "post",
        params,
    });
}
export function uploadImg(data) {
    return request({
        url: "/user/uploadImg",
        method: "post",
        contentType: "multipart/form-data",
        data,
    });
}
export function getOpinions(params) {
    return request({
        url: "/user/getOpinions",
        method: "get",
        params,
    });
}
export function getOpinionInfo(params) {
    return request({
        url: "/user/getOpinionInfo",
        method: "get",
        params,
    });
}
export function getMaliciousAccounts(params) {
    return request({
        url: "/user/getMaliciousAccounts",
        method: "get",
        params,
    });
}
export function getGoodRates(params) {
    return request({
        url: "/user/getGoodRates",
        method: "get",
        params,
    });
}
export function getAnswerTypes(params) {
    return request({
        url: "/user/getAnswerTypes",
        method: "get",
        params,
    });
}
export function getZones(params){
    return requestqr({
        url: "/user/getZones",
        method: "get",
        params,
    });
}
export function getSchools(params){
    return requestqr({
        url: "/user/getSchools",
        method: "get",
        params,
    });
}
export function getQrcode(params){
    return requestqr({
        url: "/user/getQrcode",
        method: "get",
        params,
    });
}