<template>
  <div id="app">
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
  }
}
</script>

<style>
*{
  padding:0;
  margin:0;
}
img {
	display:block;
}
#app{
  /* background:#F4F6F8; */
}
</style>
