<template>
    <div class="home">
        <!-- 校长有话说 -->
        <div class="sec1 imgbox" style="padding-top:4vw;">
            <img :src="assetsObj.img1" />
        </div>
        <!-- title -->
        <div class="sec2 imgbox">
            <img :src="assetsObj.img2" />
        </div>
        <!-- 视频 -->
        <div class="sec3 imgbox">
            <video ref="videoPlayer" controls v-if="assetsObj && assetsObj.video">
                <!-- 视频源，可以是本地文件或远程链接 -->
                <source :src="assetsObj.video" type="video/mp4">
                <!-- 如果浏览器不支持 video 标签，可以提供备用的文本内容 -->
                Your browser does not support the video tag.
            </video>
        </div>
        <!-- 吐槽 -->
        <div class="sec4 imgbox">
            <div><img :src="assetsObj.img3" /></div>
            <div>
                <!-- <img :src="assetsObj.img4" @click="onShowImagePreview(assetsObj.img4)" /> -->
                <table>
                    <thead>
                        <tr>
                            <th>日期</th>
                            <th>详情</th>
                            <th>状态</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in blackList" :key="item.id">
                            <td>{{ item.create_time }}</td>
                            <td>{{ item.content }}</td>
                            <td>{{ item.deal_status_name }}</td>
                        </tr>
                    </tbody>
                </table>

                <div></div>
            </div>
            <div class="moreBtn" @click="onNavAdvise">
                <img :src="assetsObj.img16" />
            </div>
        </div>
        <!-- 恶意抹黑 -->
        <div class="sec5 imgbox">
            <div>
                <img :src="assetsObj.img5" />
            </div>
            <div>
                <!-- <img :src="assetsObj.img6" @click="onShowImagePreview(assetsObj.img6)" /> -->
                <van-swipe :autoplay="3000" v-if="assetsObj.img6">
                    <van-swipe-item>
                        <div class="img-swipe">
                            <img :src="assetsObj.img6[0]" @click="onShowImagePreview(assetsObj.img6[0])" />
                            <img :src="assetsObj.img6[1]" @click="onShowImagePreview(assetsObj.img6[1])" />
                            <img :src="assetsObj.img6[2]" @click="onShowImagePreview(assetsObj.img6[2])" />
                        </div>
                    </van-swipe-item>
                    <van-swipe-item>
                        <div class="img-swipe">
                            <img :src="assetsObj.img6[3]" @click="onShowImagePreview(assetsObj.img6[3])" />
                            <img :src="assetsObj.img6[4]" @click="onShowImagePreview(assetsObj.img6[4])" />
                            <img :src="assetsObj.img6[5]" @click="onShowImagePreview(assetsObj.img6[5])" />
                        </div>
                    </van-swipe-item>
                    <!-- <van-swipe-item>
                        <div><img :src="assetsObj.img6[3]" /> <img :src="assetsObj.img6[4]" /></div>
                    </van-swipe-item> -->
                </van-swipe>
            </div>
            <div class="moreBtn" @click="onNavBlack">
                <img :src="assetsObj.img14" />
            </div>

        </div>
        <!-- 学员好评 -->
        <div class="sec8 imgbox">
            <img :src="assetsObj.img10" />
        </div>
        <div class="sec8 imgbox">
            <!-- <img :src="assetsObj.img11" @click="onShowImagePreview(assetsObj.img11)" /> -->
            <van-swipe :autoplay="3000" v-if="assetsObj.img11">
                <van-swipe-item>
                    <view class="img-swipe">
                        <img :src="assetsObj.img11[0]" @click="onShowImagePreview(assetsObj.img11[0])" />
                        <img :src="assetsObj.img11[1]" @click="onShowImagePreview(assetsObj.img11[1])" />
                        <img :src="assetsObj.img11[2]" @click="onShowImagePreview(assetsObj.img11[2])" />
                    </view>
                </van-swipe-item>
                <van-swipe-item>
                    <view class="img-swipe">
                        <img :src="assetsObj.img11[3]" @click="onShowImagePreview(assetsObj.img11[3])" />
                        <img :src="assetsObj.img11[4]" @click="onShowImagePreview(assetsObj.img11[4])" />
                        <img :src="assetsObj.img11[5]" @click="onShowImagePreview(assetsObj.img11[5])" />
                    </view>
                </van-swipe-item>
                <!-- <van-swipe-item>
                        <div><img :src="assetsObj.img6[3]" /> <img :src="assetsObj.img6[4]" /></div>
                    </van-swipe-item> -->
            </van-swipe>
            <div class="moreBtn" @click="onNavGood">
                <img :src="assetsObj.img15" />
            </div>
        </div>

        <!-- 无惧 -->
        <div class="sec6 imgbox">
            <img :src="assetsObj.img7" />
        </div>
        <!-- 十八年我们和学生一起进步 -->
        <div class="sec7-title imgbox" ref="progress" id="progress">
            <img :src="assetsObj.img8" />
        </div>
        <div class="sec7 imgbox">
            <img v-for="(item, index ) in assetsObj.img9" :key="index" :src="item" @click="onShowImagePreview(item)" />
        </div>

        <!-- 点击在线反馈 -->
        <div class="sec9 imgbox" @click="onNavQuestion">
            <img :src="assetsObj.img12" />
        </div>
        <!-- 底部模块 -->
        <div class="sec10 imgbox">
            <img :src="assetsObj.img13" />
        </div>
        <van-tabbar v-model="active" @change="onChange" inactive-color="#999999">
            <van-tabbar-item icon="wap-home-o">首页</van-tabbar-item>
            <van-tabbar-item icon="bar-chart-o" @click="scrollToSection('#progress')">我们的进步</van-tabbar-item>
            <van-tabbar-item icon="chat-o">问题反馈</van-tabbar-item>
            <!-- <van-tabbar-item icon="setting-o">标签 4</van-tabbar-item> -->
        </van-tabbar>
    </div>
</template>
<script>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { showImagePreview } from 'vant';
import { getOpinions } from "@/request/api.js";
export default {
    name: "homeM",
    setup() {
        const router = useRouter();
        const route = useRoute();
        const assetsObj = ref([]);
        const blackList = ref([]);
        const table = ref([]);
        fetch('/assets.json');
        const onChange = (index) => {
            if (index === 2) {
                router.push('/questionM');
            }
        };
        const scrollToSection = (sectionId) => {
            setTimeout(() => {
                const element = document.querySelector(sectionId);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }, 200);
        };
        const getAssets = async () => {
            const data = await fetch('/assets.json');
            const jsonData = await data.json();
            assetsObj.value = jsonData['home-mobile'];
            console.log('getAssets data', assetsObj.value);
        }
        function isMobileDevice() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        }
        const getBlackList = async () => {
            const { data } = await getOpinions({});
            if (data && data.data && data.data.list) {
                console.log('data.data.list.slice(0, 4)', data.data.list.slice(0, 4));
                blackList.value = data.data.list.slice(0, 4);
            }

        }
        onMounted(async () => {
            if (!isMobileDevice()) {
                router.replace("/homepc");
            }

            if (route.query.type) {
                scrollToSection("#" + route.query.type);
            }
            await getAssets();
            getBlackList();
            console.log('Component mounted');
        });
        return {
            // icon,
            onChange,
            assetsObj,
            scrollToSection,
            table,
            blackList,
            getBlackList
        };
    },
    methods: {
        onShowImagePreview(url) {
            showImagePreview([url]);
        },
        onNavQuestion() {
            this.$router.push('/questionM');
        },
        onNavAdvise() {
            this.$router.push('/adviseM');
        },
        onNavBlack() {
            this.$router.push('/blacklistM');
        },
        onNavGood() {
            this.$router.push('/goodlistM');
        }
    }
}
</script>
<style lang="scss" scoped>
.home {
    // background: #F4F6F8;
    background: white;
    padding-bottom: 15vw;

    table {
        width: 100%;
        border: 1px solid #e8e8e8;
        border-collapse: collapse;
        table-layout: fixed;

        th {
            border: 1px solid #e8e8e8;
            background: #f79f1b;
            font-size: 3vw;
            height: 6vw;
            color: white;

        }

        td {
            border: 1px solid #e8e8e8;
            height: 6vw;
            font-size: 3vw;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 33%;
        }
    }

    .imgbox {
        background: white;
        margin: 0 4vw;
        position: relative;

        img {
            width: 100%;
            height: auto;
        }

        .more {
            text-align: center;
            font-size: 3vw;
            padding: 3vw 0;
            color: rgba(128, 128, 128, 1);
        }

        video {
            width: 100%;
            height: 40vw;
        }


    }

    .sec6 {
        margin-top: 4vw;
    }

    .sec7 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        img {
            width: 41.5vw;
            margin: 3vw 0vw 0 0;
        }
    }

    img {
        width: 100%;

    }

    .img-swipe {
        display: flex;
        justify-content: space-between;

        img {
            width: 31%;
        }
    }

    .sec9 {
        margin-top: 3vw;
    }

    .e-button {
        // position: absolute;
        color: white;
        font-size: 3.1vw;
        background: rgba(191, 8, 8, 1);
        bottom: 1vw;
        right: 1vw;
        padding: 1vw 2vw;
        border-radius: 1vw;
    }
}

.moreBtn {
    cursor: pointer;
}
</style>