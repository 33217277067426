import axios from 'axios'
// 创建一个 axios 实例
const requestqr = axios.create({
	// baseURL: '/api', // 所有的请求地址前缀部分
	baseURL: 'https://bmlite-admin.zqbeike.com/api', // 所有的请求地址前缀部分
})

// 添加请求拦截器
requestqr.interceptors.request.use(
	function (config) {
		console.log('config',config);
		// 在发送请求之前做些什么
		config.headers['Content-Type'] = 'multipart/form-data';
		return config
	},
	function (error) {
		// 对请求错误做些什么
		console.log(error)
		return Promise.reject(error)
	}
)

// 添加响应拦截器
requestqr.interceptors.response.use(
	function (response) {
		console.log(response)
		// 2xx 范围内的状态码都会触发该函数。
		// 对响应数据做点什么
		// dataAxios 是 axios 返回数据中的 data
		const dataAxios = response
		// 这个状态码是和后端约定的
		// const code = dataAxios.reset
		return dataAxios
	},
	function (error) {
		// 超出 2xx 范围的状态码都会触发该函数。
		// 对响应错误做点什么
		console.log(error)
		return Promise.reject(error)
	}
)
export default requestqr
