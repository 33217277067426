import { createRouter, createWebHashHistory } from "vue-router";
import homeM from "@/views/home.vue";
import blacklistM from "@/views/blacklist.vue";
import adviseM from "@/views/advise.vue";
import questionM from "@/views/question.vue";
import homepc from "@/views/homepc.vue";
import blacklistpc from "@/views/blacklistpc.vue";
import advisepc from "@/views/advisepc.vue";
import questionpc from "@/views/questionpc.vue";
import goodlistM from "@/views/goodlist.vue";
import goodlistpc from "@/views/goodlistpc.vue";
import applicationM from "@/views/application.vue";
const routes = [
    {
        path: "/",
        component: homeM,
    },
    {
        path: "/homepc",
        name: "homepc",
        component: homepc,
    },
    {
        path: "/goodlistM",
        name: "goodlistM",
        component: goodlistM,
    },
    {
        path: "/goodlistpc",
        name: "goodlistpc",
        component: goodlistpc,
    },
    {
        path: "/blacklistpc",
        name: "blacklistpc",
        component: blacklistpc,
    },
    {
        path: "/advisepc",
        name: "advisepc",
        component: advisepc,
    },
    {
        path: "/questionpc",
        name: "questionpc",
        component: questionpc,
    },
    {
        path: "/homeM",
        name: "homeM",
        component: homeM,
    },
    {
        path: "/blacklistM",
        name: "blacklistM",
        component: blacklistM,
    },
    {
        path: "/adviseM",
        name: "adviseM",
        component: adviseM,
    },
    {
        path: "/questionM",
        name: "questionM",
        component: questionM,
    },
    {
        path: "/applicationM",
        name: "applicationM",
        component: applicationM,
    },
    
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 }; // 将滚动条位置置于顶部
    },
});

export default router;
