<template>
    <div class="question">
        <van-nav-bar left-arrow title="问题反馈" @click-left="onClickLeft" class="bar" />
        <van-form @failed="onFailed" @submit="onSubmit">
            <van-cell-group inset>
                <!-- 通过 pattern 进行正则校验 -->
                <van-field
                    v-model="type"
                    is-link
                    readonly
                    name="type"
                    label="问题类别"
                    placeholder="请选择问题类别"
                    @click="showTypePicker = true"
                    :rules="[{ required: true, message: '请选择问题类别' }]"
                />
                <van-popup v-model:show="showTypePicker" position="bottom">
                    <van-picker :columns="typeList" @confirm="onTypeConfirm" @cancel="showTypePicker = false" />
                </van-popup>
                <van-field
                    v-model="content"
                    :rules="[{ required: true, message: '请输入您遇到的问题' }]"
                    label="问题和反馈"
                    type="textarea"
                    name="content"
                    placeholder="请输入您遇到的问题"
                />
                <van-field label="图片上传" type="textarea" name="imgs" :rules="[{ required: true, message: '请上传图片' }]">
                    <template #input>
                        <van-uploader v-model="imgs" :after-read="afterRead" />
                    </template>
                </van-field>

                <!-- 通过 validator 进行函数校验 -->
                <van-field
                    v-model="name"
                    label="联系人"
                    name="name"
                    placeholder="请输入联系人姓名"
                    :rules="[{ required: true, message: '请输入联系人姓名' }]"
                />
                <van-field
                    v-model="phone"
                    label="手机号"
                    name="phone"
                    placeholder="请输入联系方式"
                    :rules="[{ required: true, message: '请输入联系方式' }]"
                />
                <van-field
                    v-model="is_safe"
                    is-link
                    readonly
                    name="is_safe"
                    label="是否保密"
                    placeholder="是否隐藏问题详情"
                    :rules="[{ required: true, message: '请选择是否隐藏问题详情' }]"
                    @click="showSecrecyPicker = true"
                />
                <van-popup v-model:show="showSecrecyPicker" position="bottom">
                    <van-picker :columns="secrecyList" @confirm="onSecrecyConfirm" @cancel="showSecrecyPicker = false" />
                </van-popup>
            </van-cell-group>
            <div style="margin: 16px">
                <van-button round block type="primary" native-type="submit"> 提交反馈 </van-button>
            </div>
        </van-form>
        <van-dialog v-model:show="isShowCode" title="反馈成功" @confirm="onDialogConfirm">
           <div class="dialog-box">
                <div class="dialog-item">反馈编码：{{ dialogCode }}</div>
                <div class="dialog-item">所有反馈将在3个工作日内回复！</div>
                <div class="dialog-item">（保密反馈仅能通过编码查询，请保存好自己的编码）</div>
           </div>
        </van-dialog>
    </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { addOpinion, uploadImg } from "@/request/api.js";
import { showToast } from "vant";
import { useRouter } from "vue-router";
export default {
    name: "questionM",
    setup() {
        const router = useRouter();
        const showTypePicker = ref(false);
        const isShowCode = ref(false);
        const dialogCode = ref("");
        const typeList = [
            { text: "专升本", value: "专升本" },
            { text: "学考", value: "学考" },
            { text: "考研", value: "考研" },
        ];
        const typeListMap = {
            专升本: "1",
            学考: "2",
            考研: "3",
        };
        const secrecyList = [
            { text: "是", value: "是" },
            { text: "否", value: "否" },
        ];
        const secrecyListMap = {
            是: "1",
            否: "0",
        };
        const imgs = ref([]);
        const content = ref("");
        const name = ref("");
        const phone = ref("");
        const onClickLeft = () => history.back();
        const type = ref("");
        const is_safe = ref("");
        const showSecrecyPicker = ref(false);
        const onDialogConfirm = ()=>{
            isShowCode.value = false;
            router.push('/homepc');
        }
        const onSubmit = async (values) => {
            console.log("submit", values);
            console.log("imgs", imgs.value[0]);
            const curData = {};
            let curImgs = [];
            imgs.value.forEach((item) => {
                console.log("imgs item", item);

                curImgs.push(item.url);
            });
            console.log("curImgs");
            curData.type = typeListMap[type.value];
            curData.name = name.value;
            curData.phone = phone.value;
            curData.content = content.value;
            curData.img = curImgs.join(",");
            curData.is_safe = secrecyListMap[is_safe.value];
            const { data } = await addOpinion(curData);
            if (data.code === 0) {
                dialogCode.value = data.data.code;
                isShowCode.value = true;
            } else {
                showToast(data.msg);
            }
        };
        const afterRead = async (file) => {
            console.log("file", file);
            console.log("imgs.value2", imgs.value);
            console.log("imgs.value2 length", imgs.value.length);
            file.status = "uploading";
            file.message = "上传中...";
            const formData = new FormData();
            formData.append("image", file.file);
            const { data } = await uploadImg(formData);
            if (data.code === 0) {
                imgs.value[imgs.value.length - 1] = {
                    url: data.data.image,
                };
            }
            console.log("imgs.imgs", imgs.value);
            // imgs.value.push({url:data.data.image});
            file.status = "success";
            console.log("data---", data);
            // setTimeout(() => {
            //     file.status = 'failed';
            //     file.message = '上传失败';
            // }, 1000);
        };
        const onFailed = (errorInfo) => {
            console.log("failed", errorInfo);
        };
        const validImg = () => {
            return false;
            // if(imgs.value.length===0){
            //     return false;
            // }else{
            //     return true;
            // }
        };
        function isMobileDevice() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        }
        onMounted(async () => {
            if (!isMobileDevice()) {
                router.replace("/questionpc");
            }
        });
        return {
            showTypePicker,
            typeList,
            type,
            content,
            imgs,
            name,
            phone,
            is_safe,
            showSecrecyPicker,
            secrecyList,
            validImg,
            afterRead,
            onSubmit,
            onFailed,
            onClickLeft,
            dialogCode,
            isShowCode,
            onDialogConfirm
        };
    },
    methods: {
        onTypeConfirm(v) {
            console.log("getBlackList", addOpinion);
            // addOpinion();
            console.log(v);
            this.type = v.selectedValues[0];
            this.showTypePicker = false;
        },
        onSecrecyConfirm(v) {
            this.is_safe = v.selectedValues[0];
            this.showSecrecyPicker = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.question {
    .bar {
        margin-bottom: 3vw;
    }

    min-height: 100vh;
    background: #f4f6f8;
    // background: white;
    padding-bottom: 15vw;

    .imgbox {
        background: white;
        margin: 0 4vw;
        position: relative;

        img {
            width: 100%;
            height: auto;
        }

        .more {
            text-align: center;
            font-size: 3vw;
            padding: 3vw 0;
            color: rgba(128, 128, 128, 1);
        }

        video {
            width: 100%;
            height: 40vw;
        }

        .e-button {
            position: absolute;
            color: white;
            font-size: 3.1vw;
            background: rgba(191, 8, 8, 1);
            bottom: 1vw;
            right: 1vw;
            padding: 1vw 2vw;
            border-radius: 1vw;
        }
    }

    .sec6 {
        margin-top: 4vw;
    }

    .sec7 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        img {
            width: 41.5vw;
            margin: 3vw 0vw 0 0;
        }
    }

    img {
        width: 100%;
    }

    .btn-box {
        display: flex;
        justify-content: space-around;
    }

    .button {
        margin: 5vw 0;
        width: 30vw;
    }

    .list {
        margin: 4vw;
        background: #f4f6f8;
    }

    .item {
        position: relative;
        background: white;
        padding: 3vw;
        margin-bottom: 3vw;
        border-radius: 2vw;

        .tag {
            position: absolute;
            right: 4vw;
            top: 3.5vw;
            width: 12vw;
            height: 5vw;
            line-height: 5vw;
            border-radius: 1.3vw;
            text-align: center;
            font-size: 3vw;
            color: white;
            // box-shadow: 0px 0.4vw 0.6vw 0.2vw rgba(206,212,225,0.45);
            background: #ff7d48;
        }

        .text {
            color: #999999;
            font-size: 3.5vw;
            line-height: 5.5vw;

            span {
                color: #333;
                display: inline-block;
                width: 18vw;
                text-align: right;
            }
        }

        .done {
            background: #006de3;
        }

        .doing {
            background: #006de3;
        }
    }

    .question-top {
        padding-bottom: 5vw;
        // .content {
        //     height: 75vw;
        // }
    }

    .question-bottom {
        margin-top: 5vw;
    }

    .model-box {
        position: relative;

        .cross {
            position: absolute;
            top: 3vw;
            right: 3vw;
        }
    }
}
.dialog-box{
    padding:5vw;
    .dialog-item{
        line-height:7vw;
        font-size:3.5vw;
    }
}
</style>
