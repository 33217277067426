<template>
    <div class="pc-container">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :ellipsis="false"
            @select="handleSelect">
            <img class="logo" src="../../public/imgs/logo.png" />
            <div class="flex-grow" />
            <el-menu-item index="1" @click="onNavQuestion('/homepc')">首页</el-menu-item>
            <el-button class="process" type="plain" @click="scrollToSection('#progress')">我们的进步</el-button>
            <el-menu-item index="2" @click="onNavQuestion('/questionpc')">问题反馈</el-menu-item>
        </el-menu>
        <div class="inner-container">
            <div class="home">
                <!-- 校长有话说 -->
                <div class="sec1 imgbox" style="padding-top: 4vw">
                    <img :src="assetsObj.img1" />
                </div>
                <!-- title -->
                <div class="sec2 imgbox">
                    <img :src="assetsObj.img2" />
                </div>
                <!-- 视频 -->
                <div class="sec3 imgbox">
                    <video ref="videoPlayer" controls v-if="assetsObj && assetsObj.video">
                        <!-- 视频源，可以是本地文件或远程链接 -->
                        <source :src="assetsObj.video" type="video/mp4" />
                        <!-- 如果浏览器不支持 video 标签，可以提供备用的文本内容 -->
                        Your browser does not support the video tag.
                    </video>
                </div>
                <!-- 吐槽 -->
                <div class="sec4 imgbox">
                    <div><img :src="assetsObj.img3" /></div>
                    <div>
                        <!-- <img :src="assetsObj.img4" @click="onShowImagePreview(assetsObj.img4)" /> -->
                        <table>
                            <thead>
                                <tr>
                                    <th>日期</th>
                                    <th>详情</th>
                                    <th>状态</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in blackList" :key="item.id">
                                    <td>{{ item.create_time }}</td>
                                    <td>{{ item.content }}</td>
                                    <td>{{ item.deal_status_name }}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div></div>
                    </div>
                    <div class="moreBtn" @click="onNavAdvise">
                        <img :src="assetsObj.img16" />
                    </div>
                </div>
                <!-- 恶意抹黑 -->
                <div class="sec5 imgbox">
                    <div>
                        <img :src="assetsObj.img5" />
                    </div>
                    <div>
                        <el-carousel autoplay height="28vw">
                            <el-carousel-item>
                                <div class="img-swipe" v-if="assetsObj.img6">
                                    <el-image :src="assetsObj.img6[0]" :zoom-rate="1.2" :preview-src-list="assetsObj.img6"
                                        :initial-index="0" :preview-teleported=true />
                                    <el-image :src="assetsObj.img6[1]" :zoom-rate="1.2" :preview-src-list="assetsObj.img6"
                                        :initial-index="1" :preview-teleported=true />
                                    <el-image :src="assetsObj.img6[2]" :zoom-rate="1.2" :preview-src-list="assetsObj.img6"
                                        :initial-index="2" :preview-teleported=true />
                                </div>
                            </el-carousel-item>
                            <el-carousel-item>
                                <div class="img-swipe" v-if="assetsObj.img6">
                                    <el-image :src="assetsObj.img6[3]" :zoom-rate="1.2" :preview-src-list="assetsObj.img6"
                                        :initial-index="3" :preview-teleported=true />
                                    <el-image :src="assetsObj.img6[4]" :zoom-rate="1.2" :preview-src-list="assetsObj.img6"
                                        :initial-index="4" :preview-teleported=true />
                                    <el-image :src="assetsObj.img6[5]" :zoom-rate="1.2" :preview-src-list="assetsObj.img6"
                                        :initial-index="5" :preview-teleported=true />
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="moreBtn" @click="onNavBlack">
                        <img :src="assetsObj.img14" />
                    </div>
                </div>
                <!-- 学员好评 -->
                <div class="sec8 imgbox">
                    <img :src="assetsObj.img10" />
                </div>
                <div class="sec8 imgbox">
                    <el-carousel height="28vw" autoplay>
                        <el-carousel-item>
                            <div class="img-swipe" v-if="assetsObj.img11">
                                <!-- {{assetsObj.img11}} -->
                                <el-image :src="assetsObj.img11[0]" :zoom-rate="1.2" :preview-src-list="assetsObj.img11"
                                    :initial-index="0" :preview-teleported=true />
                                <el-image :src="assetsObj.img11[1]" :zoom-rate="1.2" :preview-src-list="assetsObj.img11"
                                    :initial-index="1" :preview-teleported=true />
                                <el-image :src="assetsObj.img11[2]" :zoom-rate="1.2" :preview-src-list="assetsObj.img11"
                                    :initial-index="2" :preview-teleported=true />
                            </div>
                        </el-carousel-item>
                        <el-carousel-item>
                            <div class="img-swipe" v-if="assetsObj.img11">
                                <!-- {{assetsObj.img11}} -->
                                <el-image :src="assetsObj.img11[3]" :zoom-rate="1.2" :preview-src-list="assetsObj.img11"
                                    :initial-index="3" :preview-teleported=true />
                                <el-image :src="assetsObj.img11[4]" :zoom-rate="1.2" :preview-src-list="assetsObj.img11"
                                    :initial-index="4" :preview-teleported=true />
                                <el-image :src="assetsObj.img11[5]" :zoom-rate="1.2" :preview-src-list="assetsObj.img11"
                                    :initial-index="5" :preview-teleported=true />
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                    <div class="moreBtn" @click="onNavGood">
                        <img :src="assetsObj.img15" />
                    </div>
                </div>
                <!-- 无惧 -->
                <div class="sec6 imgbox">
                    <img :src="assetsObj.img7" />
                </div>
                <!-- 十八年我们和学生一起进步 -->
                <div class="sec7-title imgbox" ref="progress" id="progress">
                    <img :src="assetsObj.img8" />
                </div>
                <div class="sec7 imgbox">
                    <el-image style="width: 48%; margin: 10px 1%;" v-for="(item, index) in assetsObj.img9" :key="index"
                        :src="item" :zoom-rate="1.2" :preview-src-list="[item]" :initial-index="1" fit="cover" />
                </div>
                <!-- 点击在线反馈 -->
                <div class="sec9 imgbox" @click="onNavQuestion('/questionpc')">
                    <img :src="assetsObj.img12" />
                </div>
                <!-- 底部模块 -->
                <div class="sec10 imgbox">
                    <img :src="assetsObj.img13" />
                </div>
                <!-- <van-tabbar v-model="active" @change="onChange" inactive-color="#999999">
                    <van-tabbar-item icon="wap-home-o">首页</van-tabbar-item>
                    <van-tabbar-item icon="bar-chart-o">我们的进步</van-tabbar-item>
                    <van-tabbar-item icon="chat-o">问题反馈</van-tabbar-item>
                </van-tabbar> -->
            </div>
        </div>
    </div>
</template>
<script>
// import { ref } from 'vue';
// import { showToast } from 'vant';
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { showImagePreview } from 'vant';
import { getOpinions } from "@/request/api.js";
// import { setTimeout } from "timers";
export default {
    name: "homePc",
    setup() {
        const router = useRouter();
        const route = useRoute();
        const assetsObj = ref([]);
        const blackList = ref([]);
        const activeIndex = ref(1);
        // const progress = ref(null);
        fetch("/assets.json");
        // console.log('response',response);
        // const active = ref(0);
        const onChange = (index) => {
            if (index === 2) {
                router.push("/questionM");
            }
        };
        const getAssets = async () => {
            const data = await fetch("/assets.json");
            const jsonData = await data.json();
            assetsObj.value = jsonData["home-pc"];
            console.log("getAssets data", assetsObj.value);
        };
        const scrollToSection = (sectionId) => {
            setTimeout(() => {
                const element = document.querySelector(sectionId);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }, 500);
        };
        const getBlackList = async () => {
            const { data } = await getOpinions({});
            if (data && data.data && data.data.list) {
                blackList.value = data.data.list.slice(0, 4);
            }
        }
        function isMobileDevice() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        }
        onMounted(async () => {
            if (isMobileDevice()) {
                router.replace("/homeM");
            }
            if (route.query.type) {
                scrollToSection("#" + route.query.type);
            }
            await getAssets();
            getBlackList();
            console.log("Component mounted", route.query.type);
        });
        return {
            // icon,
            onChange,
            assetsObj,
            activeIndex,
            scrollToSection,
            blackList,
            getBlackList
        };
    },
    methods: {
        onShowImagePreview(url) {
            showImagePreview([url]);
        },
        onNavQuestion(url) {
            this.$router.push(url);
        },
        onNavAdvise() {
            this.$router.push("/advisepc");
        },
        onNavBlack() {
            this.$router.push("/blacklistpc");
        },
        onNavGood() {
            this.$router.push('/goodlistpc');
        }
    },
};
</script>
<style lang="scss" scoped>
.pc-container {
    background: #f4f5f7;

    .logo {
        height: 50px;
    }
}

table {
    width: 100%;
    border: 1px solid #e8e8e8;
    border-collapse: collapse;
    table-layout: fixed;

    th {
        border: 1px solid #e8e8e8;
        background: #f79f1b;
        font-size: 30px;
        height: 60px;
        color: white;

    }

    td {
        border: 1px solid #e8e8e8;
        height: 60px;
        font-size: 30px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 33%;
    }
}

.inner-container {
    padding: 0 15vw;
}

.top-nav {
    background: white;
}

.home {
    // background: #F4F6F8;
    background: white;
    padding-bottom: 30px;

    .imgbox {
        background: white;
        margin: 0 4vw;
        position: relative;

        img {
            width: 100%;
            height: auto;
        }

        .more {
            text-align: center;
            font-size: 30px;
            padding: 3vw 0 4vw;
            color: rgba(128, 128, 128, 1);
            cursor: pointer;
        }

        video {
            width: 100%;
            height: 40vw;
        }

        .e-button {
            color: white;
            font-size: 1.1vw;
            background: rgba(191, 8, 8, 1);
            bottom: 0vw;
            right: 0.1vw;
            padding: 0.5vw 1vw;
            border-radius: 0.5vw;
            cursor: pointer;
        }
    }

    .sec6 {
        margin-top: 4vw;
    }

    .sec7 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        img {
            width: 48%;
            margin: 10px 1%;
        }
    }

    .sec9 {
        cursor: pointer;
        margin-top: 10px;
    }

    img {
        width: 100%;
    }
}

.process {
    border: 0;
    height: 56px;
    color: #303133;

    a {
        color: #303133;
    }
}

.img-swipe {
    display: flex;
    justify-content: space-around;

    ::v-deep img {
        width: 90%;
        height: 100%;
    }

    ::v-deep .el-image {
        width: 90%;
        height: 100%;
    }
}

.moreBtn {
    cursor: pointer;
}
</style>
