<template>
    <div class="home">
        <van-nav-bar left-arrow title="恶意账号公示" @click-left="onClickLeft" />
        <van-dropdown-menu ref="dropdown">
            <van-dropdown-item title="筛选" ref="itemRef">
                <van-cell center title="平台"><van-Field placeholder="请输入平台" v-model="platform" /></van-cell>
                <van-cell center title="账号ID"><van-Field placeholder="请输入平台" v-model="actId" /></van-cell>
                <van-cell center title="昵称"><van-Field placeholder="请输入平台" v-model="nickame" /></van-cell>
                <van-cell center title="关键字"><van-Field placeholder="请输入关键字" v-model="word" /></van-cell>
                <div class="btn-box">
                    <van-button class="search-button button" size="small" type="primary" @click="onSearch">查询</van-button>
                    <van-button class="reset-button button" size="small" type="danger" @click="onReset">重置</van-button>
                </div>
            </van-dropdown-item>
        </van-dropdown-menu>
        <div class="list">
            <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                <div class="item" v-for="(item, index) in list" :key="index">
                    <div class="code text" @click="onShowDetails(item)"><span>平台：</span>{{ item.platform }}</div>
                    <div class="date text" @click="onShowDetails(item)"><span>账号ID：</span>{{ item.act_id }}</div>
                    <div class="date text" @click="onShowDetails(item)"><span>昵称：</span>{{ item.nick_name }}</div>
                    <div class="dec text van-multi-ellipsis--l2" @click="onShowDetails(item)"><span>行为认定：</span>{{ item.content }}</div>
                    <div class="dec text " style="display:flex;align-items: center;"><span>图片：</span><img :src="item.img" @click="onShowImagePreview(item.img)" /></div>
                </div>
            </van-list>
        </div>
        <!-- details -->
        <van-action-sheet v-model:show="isShowDetails" title="详情" :style="{ padding: '2vw', height: '150vw' }" position="bottom">
            <div class="model-box">
                <div class="question-top van-hairline--bottom">
                    <div class="content">
                        {{ content }}
                    </div>
                </div>
            </div>
        </van-action-sheet>
    </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { getMaliciousAccounts } from "@/request/api.js";
import { useRouter } from "vue-router";
import { showImagePreview } from 'vant';
export default {
    name: "blacklistM",
    setup() {
        const actId = ref("");
        const platform = ref("");
        const word = ref("");
        const nickame = ref("");
        const content = ref("");
        const loading = ref(false);
        const finished = ref(true);
        const dialogVisible = ref(false);
        const isShowDetails = ref(false);
        let list = ref([]);
        const router = useRouter();
        const statusList = [
            { text: "已解决", value: 0 },
            { text: "处理中", value: 1 },
            { text: "未处理", value: 2 },
        ];
        const onClickLeft = () => history.back();
        const opinions = async () => {
            const curData = {};
            if (nickame.value) {
                curData.nick_name = nickame.value;
            }
            if (word.value) {
                curData.keyword = word.value;
            }
            if (platform.value) {
                curData.platform = platform.value;
            }
            if (actId.value) {
                curData.act_id = actId.value;
            }
            const { data } = await getMaliciousAccounts(curData);
            list.value = data.data.list;
        };
        function isMobileDevice() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        }
        onMounted(async () => {
            if (!isMobileDevice()) {
                router.replace("/blacklistpc");
            }
            opinions();
            console.log("Component mounted");
        });
        return {
            nickame,
            word,
            platform,
            actId,
            statusList,
            onClickLeft,
            loading,
            finished,
            isShowDetails,
            opinions,
            list,
            content,
            dialogVisible
        };
    },
    methods: {
        onSearch() {
            this.opinions();
            this.$refs.dropdown.close();
        },
        onReset() {
            this.date = "";
            this.word = "";
            this.code = "";
            this.status = "";
            this.opinions();
            this.$refs.dropdown.close();
        },
        onShowDetails(item) {
            this.isShowDetails = true;
            this.content = item.content;
        },
        getMaliciousAccounts() {
            this.isShowDetails = true;
        },
        onShowImagePreview(url) {
            showImagePreview([url]);
        }
    },
};
</script>
<style lang="scss" scoped>
.home {
    min-height: 100vh;
    background: #f4f6f8;
    // background: white;
    padding-bottom: 15vw;

    .imgbox {
        background: white;
        margin: 0 4vw;
        position: relative;

        img {
            width: 100%;
            height: auto;
        }

        .more {
            text-align: center;
            font-size: 3vw;
            padding: 3vw 0;
            color: rgba(128, 128, 128, 1);
        }

        video {
            width: 100%;
            height: 40vw;
        }

        .e-button {
            position: absolute;
            color: white;
            font-size: 3.1vw;
            background: rgba(191, 8, 8, 1);
            bottom: 1vw;
            right: 1vw;
            padding: 1vw 2vw;
            border-radius: 1vw;
        }
    }

    .sec6 {
        margin-top: 4vw;
    }

    .sec7 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        img {
            width: 41.5vw;
            margin: 3vw 0vw 0 0;
        }
    }

    img {
        width: 100%;
    }

    .btn-box {
        display: flex;
        justify-content: space-around;
    }

    .button {
        margin: 5vw 0;
        width: 30vw;
    }

    .list {
        margin: 4vw;
        background: #f4f6f8;
    }

    .item {
        position: relative;
        background: white;
        padding: 3vw;
        margin-bottom: 3vw;
        border-radius: 2vw;

        .tag {
            position: absolute;
            right: 4vw;
            top: 3.5vw;
            width: 12vw;
            height: 5vw;
            line-height: 5vw;
            border-radius: 1.3vw;
            text-align: center;
            font-size: 3vw;
            color: white;
            // box-shadow: 0px 0.4vw 0.6vw 0.2vw rgba(206,212,225,0.45);
            background: #ff7d48;
        }

        .text {
            color: #999999;
            font-size: 3.5vw;
            line-height: 5.5vw;

            span {
                color: #333;
                display: inline-block;
                width: 18vw;
                text-align: right;
            }
        }

        .done {
            background: #006de3;
        }

        .doing {
            background: #006de3;
        }
    }

    .question-top {
        padding-bottom: 5vw;
        // .content {
        //     height: 75vw;
        // }
    }

    .question-bottom {
        margin-top: 5vw;
    }

    .model-box {
        position: relative;

        .cross {
            position: absolute;
            top: 3vw;
            right: 3vw;
        }
    }
}
</style>
