<template>
    <div class="pc-container">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :ellipsis="false" @select="handleSelect">
            <img class="logo" src="../../public/imgs/logo.png" />
            <div class="flex-grow" />
            <el-menu-item index="1" @click="onNavQuestion('/homepc')">首页</el-menu-item>
            <el-button class="process" type="plain" @click="onNavQuestion('/homepc?type=progress')">我们的进步</el-button>
            <el-menu-item index="2" @click="onNavQuestion('/questionpc')">问题反馈</el-menu-item>
        </el-menu>
        <div class="bg-w content">
            <div class="tab">
                <div class="title">恶意账号公示</div>
                <div class="line"></div>
            </div>
            <div>
                <el-form :inline="true" :model="formInline" class="demo-form-inline">
                    <el-form-item label="平台">
                        <el-input v-model="formInline.platform" placeholder="请输入平台名称" clearable />
                    </el-form-item>
                    <el-form-item label="账号ID">
                        <el-input v-model="formInline.act_id" placeholder="请输入账号ID" clearable />
                    </el-form-item>
                    <el-form-item label="昵称">
                        <el-input v-model="formInline.nick_name" placeholder="请输入昵称" clearable />
                    </el-form-item>
                    <el-form-item label="关键字">
                        <el-input v-model="formInline.keyword" placeholder="请输入关键字" clearable />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSearch">查询</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="danger" @click="onReset">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div>
                <el-table :data="tableData" stripe style="width: 100%">
                    <el-table-column prop="platform" label="平台" width="180" />
                    <el-table-column prop="act_id" label="账号ID" width="180" />
                    <el-table-column prop="nick_name" label="昵称" width="180" />
                    <el-table-column prop="content" label="行为认定">
                        <template #default="scope">
                            <div>
                                <el-popover
                                    trigger="hover"
                                    class="box-item"
                                    :width="400"
                                    :content="scope.row.content"
                                    placement="top-start"
                                >
                                    <template #reference>
                                        <span class="line-1" style="margin-left: 10px">{{ scope.row.content }}</span>
                                    </template>
                                </el-popover>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="img" label="图片" width="180" >
                        <template #default="scope">
                            <div><el-image style="width:50px;" v-if="scope.row.img" :src="scope.row.img" :zoom-rate="1"
                                    :preview-src-list="[scope.row.img]" :initial-index="0" 
                                    :preview-teleported=true /></div>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="display: flex; justify-content: flex-end">
                    <el-pagination
                        style="padding: 20px"
                        background
                        layout="prev, pager, next"
                        v-model:current-page="pageIndex"
                        v-model:page-size="pagesize"
                        :total="pageNum"
                        @preClick="onPreClick"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { getMaliciousAccounts } from "@/request/api.js";
import { useRouter } from "vue-router";
export default {
    name: "blacklistM",
    setup() {
        const actId = ref("");
        const platform = ref("");
        const word = ref("");
        const nickame = ref("");
        const content = ref("");
        const loading = ref(false);
        const finished = ref(true);
        const isShowDetails = ref(false);
        const formInline = ref({});
        const tableData = ref([]);
        const pagesize = ref(10);
        const pageIndex = ref(1);
        const pageNum = ref(0);
        let list = ref([]);
        const router = useRouter();
        const statusList = [
            { text: "已解决", value: 0 },
            { text: "处理中", value: 1 },
            { text: "未处理", value: 2 },
        ];
        const onClickLeft = () => history.back();
        const opinions = async () => {
            const curData = {};
            if (nickame.value) {
                curData.nick_name = nickame.value;
            }
            if (word.value) {
                curData.keyword = word.value;
            }
            if (platform.value) {
                curData.platform = platform.value;
            }
            if (actId.value) {
                curData.act_id = actId.value;
            }
            const { data } = await getMaliciousAccounts({ ...formInline.value, page_size: pagesize.value, page_index: pageIndex.value });
            tableData.value = data.data.list;
            pageNum.value = data.data.page_info.total_num;
        };
        const onPreClick = () => {
            console.log("onPreClick");
        };
        const handleSizeChange = (val) => {
            console.log(`${val} items per page`);
        };
        const handleCurrentChange = (val) => {
            console.log(`current page: ${val}`);
            opinions();
        };
        function isMobileDevice() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        }
        onMounted(async () => {
            if (isMobileDevice()) {
                router.replace("/blacklistM");
            }
            opinions();
            console.log("Component mounted");
        });
        return {
            nickame,
            word,
            platform,
            actId,
            statusList,
            onClickLeft,
            loading,
            finished,
            isShowDetails,
            opinions,
            list,
            content,
            formInline,
            tableData,
            pageNum,
            pageIndex,
            pagesize,
            onPreClick,
            handleSizeChange,
            handleCurrentChange,
        };
    },
    methods: {
        onNavQuestion(url){
            this.$router.push(url);
        },
        onSearch() {
            this.pageNum = 1;
            this.opinions();
        },
        onReset() {
            this.formInline = {};
            this.pageIndex=1;
            this.opinions();

        },
        onShowDetails(item) {
            this.isShowDetails = true;
            this.content = item.content;
        },
        getMaliciousAccounts() {
            this.isShowDetails = true;
        },
    },
};
</script>
<style lang="scss" scoped>
.pc-container {
    background: #f4f5f7;

    .logo {
        height: 50px;
    }
}

.top-nav {
    background: white;
}

.process {
    border: 0;
    height: 56px;
    color: #303133;
    a{
        color:#303133;
    }
}

.bg-w {
    background: white;
}

.content {
    padding: 0 15%;
}
.tab {
    padding: 30px 0;
    .title {
        font-size: 24px;
        color: #333333;
        line-height: 50px;
    }
    .line {
        height: 3px;
        width: 122px;
        border: 1px;
        background: #333;
    }
}
.line-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

</style>
<style >
.my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #39a9ed;
  }
</style>
