<template>
    <div class="home">
        <van-nav-bar title="报名系统" />
        <div class="content">
            <!-- 搜索 -->
            <div class="search-box">
                <img src="../assets/imgs/a-top-location.png" />
                <div @click="onShowLocation" class="search-location">
                    {{ city_name }} <van-icon name="arrow-down" />
                </div>
                <input v-model="title" @blur="onSearchBlur" />
                <!-- <van-button size="mini" type="success" @click="onSearchBlur">搜索</van-button> -->
            </div>
            <!-- 二维码 -->
            <div class="f-c qc-container">
                <div class="qc-box">
                    <div class="school-icon f-c"><img :src="details.logo" /></div>
                    <div class="school-name f-c">{{ details.title }}</div>
                    <div class="wx-code f-c">
                        <img :src="details.qrcode" />
                    </div>
                    <div class="code-tip f-c">长按识别二维码添加老师</div>
                </div>
            </div>
        </div>
        <!-- 定位 -->
        <div class="location-box f-ai-c">
            <img src="../assets/imgs/a-location.png" />{{ details.address }}
        </div>
        <!-- 客服 -->
        <div class="listen-box f-ai-c">
            <div class="listen-txt f-ai-c">
                <img src="../assets/imgs/a-listen.png" />若无法添加好友，可添加客服微信
            </div>
            <div class="listen-btn f-c" @click="onShowService">联系客服</div>
        </div>
        <!-- 客服弹窗 -->
        <van-popup v-model:show="isShowService" >
            <div class="s-box">
                <div class="s-top-img f-c">
                    <img src="../assets/imgs/pop-top.png" />
                </div>
                <div class="s-mid-txt">添加客服咨询</div>
                <div class="s-btm-img f-c">
                    <img :src="serviceQrcode.qrcode" />
                </div>
                <div class="s-tip">长按识别二维码添加客服老师</div>
            </div>
        </van-popup>
        <!-- 地区弹窗 -->
        <van-popup v-model:show="isShowLocation" round position="bottom">
            <van-picker
                :columns="locationColumns"
                @cancel="isShowLocation = false"
                :columns-field-names="columnsFieldNames"
                @confirm="onConfirmLocation"
            />
        </van-popup>
        <!-- 学校弹窗 -->
        <van-popup v-model:show="isShowSchool" round position="bottom" :overlay="false">
            <van-picker
                :columns="schoolColumns"
                @cancel="isShowSchool = false"
                :columns-field-names="columnsSchoolFieldNames"
                @confirm="onConfirmSchool"
            />
        </van-popup>
    </div>
</template>
<script>
import { getZones, getSchools, getQrcode } from "@/request/api.js";
import { ref, onMounted, toRaw, watch } from "vue";
export default {
    name: "applicationM",
    setup() {
        const isShowService = ref(false);
        const isShowLocation = ref(false);
        const isShowSchool = ref(false);
        const locationColumns = ref([]);
        const schoolColumns = ref([]);
        const columnsFieldNames = ref({
            text: "city_name",
            value: "id",
        });
        const columnsSchoolFieldNames = ref({
            text: "title",
            value: "id",
        });
        const city_name = ref("地区");
        const cityList = ref([]);
        const zone_id = ref("");
        const title = ref("");
        const details = ref({});
        const serviceQrcode = ref("");
        const getZonesFn = async () => {
            const { data } = await getZones();
            console.log("data.data.lis--", data.data.list);
            if (data.code === 0) {
                locationColumns.value = data.data.list;
                city_name.value = data.data.list[0].city_name;
                zone_id.value = data.data.list[0].id;
            }
        };
        const getSchoolsFn = async (value) => {
            const { data } = await getSchools({ page_index: 1, page_size: 10000, ...value });
            console.log("data.data", data.data);
            if (data.code === 0) {
                if (data.data.list && data.data.list.length > 0) {
                    title.value = data.data.list[0].title;
                    details.value = data.data.list[0];
                    schoolColumns.value = data.data.list;
                } else {
                    details.value = {};
                    schoolColumns.value = [];
                }
            }
        };
        const getQrcodeFn = async () => {
            const { data } = await getQrcode();
            if (data.code === 0) {
                serviceQrcode.value = data.data;
            }
        };
        const onShowService = () => {
            isShowService.value = true;
        };
        const onConfirmLocation = ({ selectedOptions }) => {
            const curData = toRaw(selectedOptions);
            city_name.value = curData[0].city_name;
            zone_id.value = curData[0].id;
            getSchoolsFn({ zone_id: zone_id.value });
           
            console.log("onConfirmLocation", selectedOptions.value);
            isShowLocation.value = false;
            isShowSchool.value = true;
            // isShowLocation.value = false;
        };
        const onConfirmSchool = ({ selectedOptions }) => {
            console.log("onConfirmSchool", selectedOptions[0].address);
            if (selectedOptions && selectedOptions.length > 0) {
                title.value = selectedOptions[0].title;
                isShowSchool.value = false;
            }

            // schoolColumns.value = false;
        };
        const onShowLocation = () => {
            isShowLocation.value = true;
        };
        const onSearchBlur = () => {
            console.log("onSearchBlur");
            isShowSchool.value = true;
            getSchoolsFn({ title: title.value, zone_id: zone_id.value });
        };
        onMounted(async () => {
            await getZonesFn();
            getSchoolsFn({ zone_id: zone_id.value });
            getQrcodeFn();
            console.log("Component mounted");
        });
        watch(city_name, (newValue, oldValue) => {
            console.log(newValue, oldValue);
            // title.value = '';
        });
        return {
            city_name,
            zone_id,
            title,
            cityList,
            details,
            isShowService,
            onShowService,
            isShowLocation,
            isShowSchool,
            locationColumns,
            schoolColumns,
            onConfirmLocation,
            onConfirmSchool,
            columnsFieldNames,
            columnsSchoolFieldNames,
            onShowLocation,
            serviceQrcode,
            onSearchBlur,
        };
    },
    methods: {},
};
</script>
<style lang="scss" scoped>
.home {
    height: 100vh;
    background: white;
}

.content {
    background: url("../assets/imgs/application-bg.png");
    background-size: 100% 100%;
    height: 147.92vw;
    padding-top: 5vw;
    box-sizing: border-box;
}

.search-box {
    display: flex;
    align-items: center;
    background: white;
    margin: 0 5vw;
    height: 8.5vw;
    border-radius: 8.5vw;
    padding-left: 1.5vw;
    .search-location {
        color: #3d3d3d;
        font-size: 3.1vw;
        padding: 0 0.5vw;
    }
    img {
        height: 4.24vw;
        width: 4.24vw;
    }

    input {
        border: none;
    }
}

.qc-box {
    background: white;
    width: 77vw;
    height: 98vw;
    border-radius: 2.6vw;
}

.qc-container {
    margin-top: 10vw;
}

.school-icon {
    margin-top: 7vw;

    img {
        height: 16.57vw;
        width: 16.57vw;
    }
}

.school-name {
    margin-top: 6vw;
    color: #3d3d3d;
    font-size: 4.14vw;
    font-weight: bold;
}

.code-tip {
    color: #3d3d3d;
    font-size: 3.1vw;
    margin-top: 5vw;
}

.wx-code {
    margin-top: 6vw;

    img {
        width: 41vw;
        height: 42vw;
    }
}

.location-box {
    margin: 7vw 5vw 5vw;

    img {
        height: 7.77vw;
        width: 7.77vw;
        margin-right: 5vw;
    }
}

.listen-box {
    margin-left: 5.2vw;
    font-size: 2.6vw;
    color: #909090;

    img {
        height: 6.47vw;
        width: 6.47vw;
        margin-right: 5vw;
    }

    .listen-btn {
        border: 1px solid #909090;
        height: 6.47vw;
        width: 20.5vw;
        border-radius: 6vw;
        font-size: 3.1vw;
        color: #646464;
        margin-left: 16vw;
    }
}

.s-box {
    width: 84.5vw;
    height: 88.1vw;
    background: white;
    // border-radius:12vw;
    // overflow: hidden;
    .s-top-img {
        padding-top: 2.6vw;

        img {
            height: 16.1vw;
            width: 16.1vw;
        }
    }

    .s-mid-txt {
        padding-top: 1vw;
        text-align: center;
    }

    .s-btm-img {
        padding-top: 6vw;

        img {
            width: 40.9vw;
            height: 40.9vw;
        }
    }

    .s-tip {
        padding-top: 3.9vw;
        text-align: center;
        font-size: 3.1vw;
        color: #3d3d3d;
    }
}
</style>
